<template>
    <div
        class="youtube-player-text-parent"
        :class="{
            'is-tv-styled': tvStyling,
            'is-mini': !playerFull,
            'is-paused': playerPause
        }"
    >
        <!-- MUTE OR PLAY -->
        <div class="glitch-text">
            <div class="text">
                <span
                    v-for="i in 4"
                    :key="'glitch-text-'+i"
                >
                    {{ text }}
                </span>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                required: true
            },
            playerFull: {
                type: Boolean,
                default: true
            },
            playerPause: {
                type: Boolean,
                default: true
            },
            tvStyling: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    //---------------------------------------------------------
    // MUTE / PAUSE TEXT
    //---------------------------------------------------------

    .glitch-text {
        position: absolute;
        top: rem-calc(40);
        left: rem-calc(40);
        font-size: rem-calc(100);
        font-weight: 600;
        z-index: 1; // [LOCKED]
        filter: blur(1.1px);
    }

    .is-tv-styled .glitch-text {
        top: rem-calc(80);
        left: rem-calc(80);
    }

    //---------------------------------------------------------
    // IS MINI
    //---------------------------------------------------------

    // IS-MINI
    .is-mini.is-tv-styled .glitch-text {
        filter: none;
    }

    // IS-MINI
    .is-mini.is-tv-styled .glitch-text {
        top: rem-calc(20);
        left: rem-calc(35);
        font-size: rem-calc(23);
    }

    //---------------------------------------------------------
    // TEXT
    //---------------------------------------------------------

    .glitch-text .text {
        position: relative;
        padding: 0;
        margin: 0;
        animation: jerkwhole 2.5s infinite;
    }

    .glitch-text span {
        position: absolute;
        // color: white; // [COMMENTED]
        filter: blur(1px);
        animation: blur 30ms infinite, jerk 50ms infinite;
    }

    .glitch-text span:nth-child(1) {
        margin-left: 8px;
        margin-top: -2px;
        color: red;
        filter: blur(2px);
    }

    .glitch-text span:nth-child(2) {
        margin-left: 7px;
        color: green;
        filter: blur(2px);
    }

    .glitch-text span:nth-child(3) {
        margin-left: -7px;
        margin-top: 4px;
        filter: blur(2px);
        animation: jerkblue .5s infinite;
    }

    .glitch-text span:nth-child(4) {
        color: white;
        filter: blur(1px);
        text-shadow: 0 0 50px rgba(white, 0.4);
    }

    .glitch-text span:nth-child(5) {
        color: rgba(white, 0.4);
        filter: blur(15px);
    }

    //---------------------------------------------------------
    // GLITCH ANIMATION
    //---------------------------------------------------------

    @keyframes blur {
        0%   { filter: blur(1px); opacity: 0.8; }
        50%  { filter: blur(1px); opacity: 1; }
        100% { filter: blur(1px); opacity: 0.6; }
    }

    @keyframes jerk {
        50% { left: 2px; }
        51% { left: 0; }
    }

    @keyframes jerkup {
        50% { top: 2px; }
        51% { top: 0; }
    }

    @keyframes jerkblue {
        0%   { left: 0; }
        30%  { left: 0; }
        31%  { left: -10px; }
        32%  { left: 0; }
        98%  { left: 0; }
        100% { left: 10px; }
    }

    @keyframes jerkgreen {
        0%   { left: 0; }
        30%  { left: 0; }
        31%  { left: -20px; }
        32%  { left: 0; }
        98%  { left: 0; }
        100% { left: 15px; }
    }

    @keyframes jerkwhole {
        30% { }
        40% { opacity: 1; top: 0; left: 0; transform: scale(1, 1); transform: skew(0, 0); }
        41% { opacity: 0.8; top: 0; left: -100px; transform: scale(1, 1.2); transform: skew(20deg, 0); }
        42% { opacity: 0.8; top: 0; left: 50px; transform: scale(1, 1.2); transform: skew(-40deg, 0); }
        43% { opacity: 1; top: 0; left: 0; transform: scale(1, 1);  transform: skew(0, 0); }
        65% { }
    }

    //---------------------------------------------------------
    // PAUSE GRAIN
    //---------------------------------------------------------

    // .is-paused.youtube-player-text-parent:after {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: url('~@/assets/img/grain@2x.png');
    //     background-size: 500px;
    //     animation: grain-filter 0.25s steps(2) infinite;
    //     opacity: 0.7; // [LOCKED]
    //     z-index: 1;
    // }

    // .is-mini.is-paused .player-inner:after {
    //     background-size: 200px;
    // }

    // @keyframes grain-filter {
    //     from { transform: rotate(0deg); }
    //     to   { transform: rotate(-360deg); }
    // }
</style>